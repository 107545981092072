import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Footer from "../../Footer/FooterBottom";
import Backgrounds from "../../Admin/Question/View/Background";

import fileImg from "./file.png";
import examVideo from "./2.mp4";
import gameVideo from "./3.mp4";

class Registration extends React.Component {

    render() {
        const { t } = this.props;

        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.Register.title')}  | {t('alarabeyya')}
                    </title>
                </Helmet>
                <section className="pos-rel call-in-action call-overlay oh" id="register-section" style={{
                    backgroundColor: '#75cdff',
                    borderBottom: '1px solid #0e2c53',
                    backgroundAttachment: 'fixed',
                    minHeight: '100vh'
                }}>
                    <div className="position-relative padding-bottom pt-4" style={{
                        minHeight: '100vh'
                    }}>
                        <span className="d-none d-md-block">
                            <Backgrounds CustomBackground="Registration" />
                        </span>
                        <div className="container" >
                            <div className="teacher-wrapper pos-rel pt-md-5 pt-sm-2 rounded main-bg">
                                <div className=" teacher-single-content px-1 d-flex flex-column pt-3">
                                    <h3 className="title text-center font-weight-bold h2">
                                        اُحصُلْ على أكثر من 200 مادّة تعليميّة مجّانًا
                                    </h3>
                                    <div className="w-100 text-center mt-3 h5 p-3 rounded" style={{
                                        background: '#ffffff80'
                                    }}>
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            <span className="font-weight-bold mx-1">
                                                "هيّا إلى العربيّة"
                                            </span>
                                            الموقع الرائد في تعليم اللغة العربيّة في الشرق الأوسط، حيث يوفّر ملفّات ورقيّة وإلكترونيّة وألعابًا تعليميّة مبتكرة.
                                        </p>
                                    </div>
                                    <div className="form-group w-100 text-center d-flex flex-column justify-content-center align-items-center mt-3 order-sm-3 order-md-2">
                                        <Link to="/Prices" className={`custom-button`} >
                                            <span className="d-flex justify-content-center align-items-center flex-wrap">
                                                اِبدأْ تجربتك المجّانيّة الآن
                                                <i className="icon fad fa-arrow-left px-2"></i>
                                            </span>
                                        </Link>
                                        <div className="title mt-3" style={{
                                            fontSize: '18px'
                                        }}>
                                            يتمّ التسجيل في أقلّ من 60 ثانية، دون الحاجة إلى إدخال بطاقة ائتمانيّة.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row clearfix justify-content-center mt-4">
                                <div className="featured-block col-lg-3 col-md-6 col-sm-12"  >
                                    <div className="inner-box wow fadeInLeft animated d-flex h-100 flex-column justify-content-around" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                        <span className="border-one"></span>
                                        <span className="border-two"></span>
                                        <div className="icon-box d-flex justify-content-center flex-column ">
                                            <span className="icon"> <i className=" fa-light fa-download display-4 text-white"></i></span>
                                        </div>
                                        <h5 >
                                            تمَّ تَحمِيل 612،538 +
                                        </h5>
                                        <p className=" text-dark" style={{
                                            fontSize: '16px'
                                        }}  >
                                            قام مُستخدمو هيّا إلى العربيّة بتحميل أكثر من 600 ألف ملفّ.
                                        </p>
                                    </div>
                                </div>

                                <div className="featured-block col-lg-3 col-md-6 col-sm-12"  >
                                    <div className="inner-box wow fadeInLeft animated d-flex h-100 flex-column justify-content-around" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                        <span className="border-one"></span>
                                        <span className="border-two"></span>
                                        <div className="icon-box d-flex justify-content-center flex-column ">
                                            <span className="icon"> <i className=" fa-light fa-users display-4 text-white"></i></span>
                                        </div>
                                        <h5 >
                                            200 ألف مُستخدِم
                                        </h5>
                                        <p className=" text-dark" style={{
                                            fontSize: '16px'
                                        }}  >
                                            عدد مُستخدمي المواقع التعليميّة التابعة لنا أكثر من 200 ألف مستخدِم.
                                        </p>
                                    </div>
                                </div>

                                <div className="featured-block col-lg-3 col-md-6 col-sm-12"  >
                                    <div className="inner-box wow fadeInLeft animated d-flex h-100 flex-column justify-content-around" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                        <span className="border-one"></span>
                                        <span className="border-two"></span>
                                        <div className="icon-box d-flex justify-content-center flex-column ">
                                            <span className="icon"> <i className=" fa-light fa-globe display-4 text-white"></i></span>
                                        </div>
                                        <h5 >
                                            24 دولة
                                        </h5>
                                        <p className=" text-dark" style={{
                                            fontSize: '16px'
                                        }}  >
                                            لدينا زبائن في أكثر من 24 دولة حول العالم.
                                        </p>
                                    </div>
                                </div>

                                <div className="featured-block col-lg-3 col-md-6 col-sm-12"  >
                                    <div className="inner-box wow fadeInLeft animated d-flex h-100 flex-column justify-content-around" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                        <span className="border-one"></span>
                                        <span className="border-two"></span>
                                        <div className="icon-box d-flex justify-content-center flex-column ">
                                            <span className="icon"> <i className=" fa-light fa-presentation-screen display-4 text-white"></i></span>
                                        </div>
                                        <h5 >
                                            2500 ملف+
                                        </h5>
                                        <p className=" text-dark" style={{
                                            fontSize: '16px'
                                        }}  >
                                            ستجد أكثر من 2500 ملفّ، بالإضافة إلى الملفّات التي نضيفها شهريًّا.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="teacher-wrapper pos-rel mt-4 rounded pt-md-5 pt-sm-2" style={{
                                background: '#fff8eb'
                            }}>
                                <div className=" teacher-single-content p-3 ">
                                    <h3 className="title text-center mb-3">
                                        مكتبة ضخمة تضم 2500 ملفّ تعليميّ
                                    </h3>
                                    <h4 className="title text-center">
                                        (أوراق عمل وامتحانات، من الممكن تحميلها وطباعتها)
                                    </h4>
                                    <img src={fileImg} className="w-100" alt="img" />
                                    <div className="w-100 mt-3 h5">
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            يحتوي "هيّا إلى العربيّة" على أكثر من&nbsp;<span className=" font-weight-bold ">
                                                2500 ملفّ&nbsp;
                                            </span>
                                            قابل للتحميل، تشمل أوراق عمل وامتحانات تغطي&nbsp;
                                            <span className=" font-weight-bold">
                                                جميع جوانب اللغة العربيّة،
                                            </span>
                                            وتلبّي احتياجات المرحلتين الابتدائيّة والإعداديّة، لتناسب مختلف الفئات والمجتمعات.
                                        </p>
                                    </div>

                                    <h3 className="title text-center mb-3 mt-5">
                                        ألعاب تعليميّة حصريّة
                                    </h3>

                                    <video className="w-100" controls muted>
                                        <source src={examVideo} type="video/mp4" />
                                    </video>
                                    <div className="w-100 mt-3 h5">
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            لقد طوّرنا ألعابًا تعليميّة خاصّة وحصريّة لموقع "هيّا إلى العربيّة"، فهي غير متوفّرة في أيّ موقع آخر. صُمِّمت الألعاب بعناية، بدءًا من الفكرة حتّى البرمجة، لتمنحك تجربة تعليميّة فريدة لا مثيل لها.
                                        </p>
                                    </div>

                                    <h3 className="title text-center my-3">
                                        دروس وامتحانات محوسبة للتعلّم التفاعليّ
                                    </h3>

                                    <video className="w-100" controls muted>
                                        <source src={gameVideo} type="video/mp4" />
                                    </video>
                                    <div className="w-100 mt-3 h5">
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            نوفّر امتحانات وأوراق عمل يمكن حلّها عبر الحاسوب مع التصحيح التلقائيّ، ممّا يوفّر على المعلّمين وأولياء الأمور عناء التصحيح اليدويّ ويختصر وقت مراجعة الإجابات من ساعاتٍ إلى ثوانٍ معدودة. بالإضافة إلى أنّه يُقدّم تقارير دقيقة لعلامات الطالب/ الابن، ويتيح متابعة الأداء التعليميّ لحظيًّا وبسهولة.
                                        </p>
                                    </div>


                                </div>
                            </div>

                            <div className="teacher-wrapper pos-rel mt-4 rounded p-2 pt-md-3 pt-sm-2" style={{
                                background: '#fff8eb'
                            }}>
                                <div className="about-content mb-3">
                                    <div className="faq-wrapper mb--10">

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    هل أحتاج إلى بطاقة ائتمان للتسجيل في التجربة المجانيّة؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    لا، التسجيل في التجربة المجانيّة لا يتطلّب إدخال بطاقة ائتمان. يكفي النقر على
                                                    <span className="mx-1 font-weight-bold">
                                                        "ابدأ تجربتك المجانية الآن"
                                                    </span>
                                                    ، ثم اختيار اسم مستخدم وكلمة مرور، لتبدأ الاستمتاع بتجربتك المجّانيّة.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    هل تَتَوفّر موادّ تعليميّة تناسب جميع المستويات؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    نعم، الموقع مُنظّم وفقًا للصفوف الدراسيّة، ويحتوي كل صفّ على برنامج متكامل يتضمّن موادّ تعليميّة متنوّعة تتوافق مع المستوى التعليميّ لكل صف.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    ما هي المزايا المتاحة خلال التجربة المجانيّة؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    ستحصل على أكثر من 200 مادّة تعليميّة مجّانًا، تشمل ما يزيد عن 100 ملفّ محوسب، وأكثر من 50 درسًا مجّانيًّا لجميع المراحل، إضافة إلى أكثر من 50 امتحانًا محوسبًا و20 لعبة تعليمية مجّانية.
                                                    <div className="font-weight-bold mx-1">
                                                        *التسجيل لا يستغرق أكثر من 60 ثانية، ولا يتطلب إدخال بطاقة ائتمان.
                                                    </div>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    ما تكلفة الاشتراك في الموقع، وما الذي يتضمّنه الاشتراك؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    الاشتراك الفردي في الموقع يكلف 297 دولارًا سنويًا، ويشمل:
                                                    <div className="list py-1 w-100 text-right">
                                                        <ul>
                                                            <li>
                                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                إمكانيّة تحميل أيّ ملفّ ورقيّ من الموقع لمدّة سنة كاملة.

                                                            </li>
                                                            <li>
                                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                الوصول غير المحدود إلى جميع الدروس المحوسبة لمدّة سنة.

                                                            </li>
                                                            <li>
                                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                الوصول غير المحدود إلى جميع الألعاب المحوسبة لمدّة سنة.

                                                            </li>
                                                            <li>
                                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                الوصول غير المحدود إلى الكتب الإثرائيّة المحوسبة لمدّة سنة.

                                                            </li>
                                                        </ul>
                                                    </div>

                                                </p>
                                            </div>
                                        </div>


                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    كيف أتأكّد من أنّ الموقع مناسب لاحتياجاتي؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    بإمكانك ببساطة التسجيل وتجربة الأقسام المجّانيّة المتاحة لاستكشاف الموقع بنفسك. في حال لم تجد الموقع مفيدًا أو لم يلبِّ احتياجاتك، يمكنك التوقّف عن استخدامه دون الحاجة إلى الاشتراك. علمًا بأن الموقع يحظى بشعبيّة واسعة ويستخدمه متعلّمون من 24 دولة.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    هل يمكنني إرسالُ مهامٍّ محوسبة للطلاب؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    نعم، يمكنك إرسال مهامّ محوسبة لطلاب الصف أو جزء منهم، لكن هذه الميزة متاحة فقط ضمن اشتراكات المدارس، وليست متوفرة في الاشتراكات الفردية.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    ما الفرق بين الاشتراك الفردي واشتراك المدارس؟       </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    الاشتراك الفردي يمنّح إمكانية الدخول لشخص واحد فقط، أما اشتراك المدارس فيتيح إنشاء حسابات متعددة، لِكلٍّ من طاقم تدريس اللغة العربية والطلاب، حيث يمكن تحميل أسماء الطلاب وفتح حساب خاص لكل طالب، ما يتيح إرسال مهام محوسبة مباشرة إليهم.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    هل يمكن لعدّة معلّمين استخدام الموقع في الوقت ذاته عبر حساب المدرسة؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    نعم، بالطبع. يحصل كل معلم على اسم مستخدم وكلمة مرور منفصلة، ممّا يضمن استقلاليّة حساب كل معلم عن الآخرين في المدرسة.
                                                </p>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <div className="form-group w-100 text-center d-flex flex-column justify-content-center align-items-center mt-5 order-sm-3 order-md-2">
                                    <Link to="/Prices" className={`custom-button`} >
                                        <span className="d-flex justify-content-center align-items-center flex-wrap">
                                            اِبدأْ تجربتك المجّانيّة الآن
                                            <i className="icon fad fa-arrow-left px-2"></i>
                                        </span>
                                    </Link>
                                    <div className="title mt-3" style={{
                                        fontSize: '18px'
                                    }}>
                                        يتمّ التسجيل في أقلّ من 60 ثانية، دون الحاجة إلى إدخال بطاقة ائتمانيّة.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section >
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        location: state.location
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Registration));